class Utils {
    constructor() {
        const u = navigator.userAgent;
        const ua = u.toLowerCase();
        this.isWeixin = u.toLowerCase().indexOf('micromessenger') !== -1;
        this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        this.isIos = ua.indexOf('like mac os x') > 0;
        this.isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    }

    zixun() {
        const href = window.location.href;
        // 线上客服地址
        const buildUrl = "https://djbz.qiyukf.com/client?k=bf6b0de683dccf4b78e744e33a03ced5&wp=1&gid=397777823&robotShuntSwitch=1&robotId=3411383";
        // 测试环境客服地址
        const devUrl = "https://zjdjkjgfyxgs3.qiyukf.com/client?k=e3fb67185726f4fac9c8b721248e77fc&wp=1&gid=480967730&robotShuntSwitch=0";
        const url = href.includes("zbc.djcps.com") ? buildUrl : devUrl;
        window.open(url);
    }
}

export default new Utils();
